import React from "react"
import * as styles from "./blog.module.scss"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { truncString } from "../../assets/js/helper"
const BlogDetailCard = ({ blog, blogParent }) => {
  const {
    heading,
    featured_text,
    featured_image,
    slug,
    published_date,
  } = blog.elements

  return (
    <Link
      to={`/blog/${blogParent + slug.value}`}
      className={`${styles.blogCard} tooltip`}
    >
      <article>
        
        <Img  
          fluid={featured_image.value[0].fluid}
          className={styles.cardImage}
          alt={featured_image.value[0].description}
        />
        <div className={`${styles.blogCardHead} `}>
          {heading.value.length > 48 && (
            <span className="tooltiptext">{heading.value}</span>
          )}
          <p className={styles.pubDate}>{published_date.value}</p>
          <h2 className={styles.blogTitle}>{truncString(heading.value, 120)}</h2>
          <p className="align-left">
            {truncString(featured_text.value, 155)} <strong>Read More</strong>
          </p>
        </div>
      </article>
    </Link>
  )
}



export default BlogDetailCard
